<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div class="server-inspection-log">
    <!-- 查询条件 -->
    <el-form v-show="showSearch" ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item :label="$t('server.label.serverId')" prop="roleName">
        <el-input v-model="queryParams.id" :placeholder="$t('server.placeholder.serverId')" clearable size="small"
          style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('server.label.serverIp')" prop="ip">
        <el-input v-model="queryParams.ipAddress" :placeholder="$t('server.placeholder.serverIp')" clearable size="small"
          style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('server.label.machineType')" prop="machineType">
        <el-select v-model="queryParams.type" :placeholder="$t('server.placeholder.machineType')" clearable size="small"
          style="width: 200px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleSearch">{{ $t('operate.search')
        }}</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetSearch">{{ $t('operate.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <!-- 操作符 -->
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">{{ $t('operate.register')
        }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" @click="edit()">{{ $t('operate.edit')
        }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDelete">{{ $t('operate.delete')
        }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" :loading="exportLoading"
          @click="handleExport">{{ $t('operate.export') }}</el-button>
      </el-col>
    </el-row>
    <!--  表格数据  -->
    <el-table v-loading="loading" border :data="tableData" style="width: 100% ; margin-top: 2%;"
      @selection-change="handleSelectionChange">
      <!--   选框   -->
      <el-table-column type="selection" width="45" align="center" />
      <el-table-column prop="id" :label="$t('server.label.serverId')" :show-overflow-tooltip="true" width="100"
        align="center" />
      <el-table-column prop="name" :label="$t('server.label.serverName')" width="120" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column prop="ipAddress" :label="$t('server.label.ipAddress')" width="100" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column prop="hostName" :label="$t('server.label.hostName')" width="100" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column prop="machineType" :label="$t('server.label.machineType')" width="80" align="center" />
      <el-table-column prop="contactId" :label="$t('server.label.contactId')" width="80" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column prop="memory" :label="$t('server.label.memory') + '(MB)'" width="100" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column prop="diskSpace" :label="$t('server.label.diskSpace') + '(MB)'" width="100"
        :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="registeredUserId" :label="$t('server.label.registeredUserId')" :show-overflow-tooltip="true"
        width="80" align="center" />
      <el-table-column prop="registeredTime" :label="$t('server.label.registeredTime')" width="120"
        :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="updatedUserId" :label="$t('server.label.updatedUserId')" width="100"
        :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="updatedTime" :label="$t('server.label.updatedTime')" width="120"
        :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="remark" :label="$t('server.label.remark')" width="100" :show-overflow-tooltip="true"
        align="center" />
      <el-table-column :label="$t('server.label.operate')" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" icon="el-icon-edit" @click="handleUpdate(scope.row)">{{ $t('operate.edit')
          }}</el-button>
          <el-button type="text" size="small" icon="el-icon-delete" @click="deleteonSubmit(scope.row)">{{
            $t('operate.delete') }}</el-button>
          <el-button type="text" size="small" icon="el-icon-notebook-2" @click="handleDetail(scope.row)">{{
            $t('operate.serverInfo') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <div class="block" style="margin: 0 auto;text-align:center">
      <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
        layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
    <!-- 添加或修改设备模型管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverId')" prop="id">
              <el-input v-model="form.id" size="small" :disabled="idDisabled" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverName')" prop="name">
              <el-input v-model="form.name" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.hostName')" prop="hostName">
              <el-input v-model="form.hostName" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.ipAddress')" prop="ipAddress">
              <el-input v-model="form.ipAddress" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.machineType')">
              <el-select v-model="form.machineType" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.contactId')" prop="contactId">
              <el-input v-model="form.contactId" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.memory') + '(MB)'" prop="memory">
              <el-input v-model="form.memory" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.diskSpace') + '(MB)'" prop="diskSpace">
              <el-input v-model="form.diskSpace" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.remark')">
              <el-input v-model="form.remark" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.inspectionPeriod') + '(min)'" prop="inspectionPeriod">
              <el-input v-model="form.inspectionPeriod" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('server.label.inspectionConfig')" label-width="150px">
              <el-row />
              <el-row v-for="(item, index) in config" :key="index" :gutter="10">
                <el-col :span="7"><el-input v-model="item[0]" size="small" /></el-col>
                <el-col :span="1">:</el-col>
                <el-col :span="6"><el-input v-model="item[1]" size="small" /></el-col>
                <el-col :span="2.5"><el-button size="small" @click="addConfig">+</el-button></el-col>
                <el-col :span="2.5"><el-button size="small" @click="deleteConfig(index)">-</el-button></el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">{{ $t('operate.define') }}</el-button>
        <el-button @click="handleCancel">{{ $t('operate.cancel') }}</el-button>
      </div>
    </el-dialog>
    <!-- 服务器信息对话框 -->
    <el-dialog :visible.sync="drawer" center class="drawer" style="font-size: 24px;" width="660px">
      <el-form :data="drawerData" label-width="135px" disabled="disabled" label-position="right">
        <h2>{{ drawerData.title }}</h2>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverId')" prop="id">
              <el-input v-model="drawerData.id" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverName')" prop="name">
              <el-input v-model="drawerData.name" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.hostName')" prop="hostName">
              <el-input v-model="drawerData.hostName" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverIp')" prop="ip">
              <el-input v-model="drawerData.ipAddress" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.machineType')">
              <el-select v-model="drawerData.machineType" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.contactId')">
              <el-input v-model="drawerData.contactId" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.memory') + '(MB)'" prop="memory">
              <el-input v-model="drawerData.memory" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.diskSpace') + '(MB)'" prop="diskSpace">
              <el-input v-model="drawerData.diskSpace" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.inspectionPeriod') + '(min)'" prop="inspectionPeriod">
              <el-input v-model="drawerData.inspectionPeriod" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('server.label.inspectionConfig')" prop="inspectionConfig">
              <el-input type="textarea" :value="configToString(drawerData.config)" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'Server',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 详情数据 -> 数据表对应所有信息
      drawerData: {
        title: '',
        config: [{ '': '' }]
      },
      dateRange: [],
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认20条
        pageSize: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      // 总记录数
      total: 0,
      // 搜索框
      input: '',
      // 新增弹框控制
      insertdialogVidible: false,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
        ipAddress: undefined,
        id: undefined,
        type: undefined,
        machineType: undefined
      },
      // 下拉框数据
      options: [
        {
          value: 1,
          label: this.$t('server.machineType.physical')
        },
        {
          value: 2,
          label: this.$t('server.machineType.virtual')
        },
        {
          value: 3,
          label: this.$t('server.machineType.docker')
        }
      ],
      // 显示搜索条件
      showSearch: true,
      // 导出遮罩层
      exportLoading: false,
      // 抽屉控制
      drawer: false,
      title: '',
      // 编辑 新增表单数据
      form: {
        type: null, // 用于区别表单是新增还是修改
        id: null, // 服务器ID
        name: null, // 服务器名称
        ipAddress: null, // IP地址
        hostName: null, // 主机名
        machineType: null, // 机器类型
        config: [], // 巡检配置内容
        inspectionPeriod: null, // 巡检间隔时间
        contactId: null, // 联系人Id
        memory: null, // 内存大小
        diskSpace: null, // 存储大小
        remark: null, // 备注
        registeredTime: null, // 注册时间
        registeredUserId: null, // 注册用户Id
        updatedTime: null, // 更新时间
        updatedUserId: null // 更新用户Id
      },
      // 控制表单弹框
      open: false,
      // 表单规则
      rules: {
        id: [
          { required: true, message: this.$t('server.rules.message.id'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('server.rules.message.name'), trigger: 'blur' }
        ],
        ipAddress: [
          { required: true, message: this.$t('server.rules.message.ipAddress'), trigger: 'blur' }
        ],
        hostName: [
          { required: true, message: this.$t('server.rules.message.hostName'), trigger: 'blur' }
        ],
        inspectionConfig: [
          { required: true, message: this.$t('server.rules.message.inspectionConfig'), trigger: 'blur' }
        ],
        memory: [
          { required: true, message: this.$t('server.rules.message.memory'), trigger: 'blur' }
        ],
        diskSpace: [
          { required: true, message: this.$t('server.rules.message.diskSpace'), trigger: 'blur' }
        ],
        contactId: [
          { required: true, message: this.$t('server.rules.message.contactId'), trigger: 'blur' }
        ]
      },
      // 控制label
      labelPosition: 'left',
      // 控制ID的disabled
      idDisabled: false,
      // 记录选中行信息
      val: {},
      config: [['', '']]
    }
  },
  created() {
    // 获取请求参数，修改字段语言
    this.changeLocale()
    // 获取列表数据
    this.getList()
  },
  methods: {
    /*
    **
    * @methods getList
    * @description 获取列表数据
    * @param {String} url '/v1.0/ServerService/servers'+ queryParams
    */
    getList() {
      this.loading = true
      // 发送请求数据
      this.$api.getServerList(this.queryParams).then(res => {
        console.log(res.data.list)
        // 拿到结果先判断机器类型
        res.data.list.forEach(item => {
          this.changeMachineType(item)
          item.registeredTime = this.changeTime(item.registeredTime)
          item.updatedTime = this.changeTime(item.updatedTime)
        })
        this.tableData = res.data.list
        this.tableData.forEach(item => {
          item.config = this.getConfig(item)
        })
        console.log(res.data.list)
        this.total = res.data.total
      })
      // 取消遮罩层
      this.loading = false
    },
    /**
    * @methods reset
    * @description 表单重置
    */
    reset() {
      this.form = {
        type: null, // 用于区别表单是新增还是修改
        id: null, // 服务器ID
        name: null, // 服务器名称
        ipAddress: null, // IP地址
        hostName: null, // 主机名
        machineType: null, // 机器类型
        inspectionPeriod: null, // 巡检间隔时间
        contactId: null, // 联系人Id
        memory: null, // 内存大小
        diskSpace: null, // 存储大小
        remark: null, // 备注
        registeredTime: null, // 注册时间
        registeredUserId: null, // 注册用户Id
        updatedTime: null, // 更新时间
        updatedUserId: null, // 更新用户Id
        config: [{ '': '' }] // 巡检配置内容
      }
      this.config = [['', '']]
    },
    /**
    * @methods resetQuery
    * @description 重置查询条件
    */
    resetQuery() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
        ipAddress: undefined,
        id: undefined,
        machineType: undefined,
        type: undefined
      }
    },
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getList()
    },
    /**
    * @methods changeLocale
    * @description 修改语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$set(this.options, 0, { value: 1, label: this.$t('server.machineType.physical') })
        this.$set(this.options, 1, { value: 2, label: this.$t('server.machineType.virtual') })
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods getConfig
    * @description inspectionConfig格式转换为json字符串
    * @param {Object} params 获取到的inspectionConfig
    */
    getConfig(data) {
      const inspectionConfigObject = JSON.parse(data.inspectionConfig)
      const array = []
      for (const key in inspectionConfigObject) {
        const object = {}
        object[key] = inspectionConfigObject[key]
        array.push(object)
      }
      // console.error("array",array)
      const jsonObject = {}
      array.forEach(item => {
        // console.error("item",item)
        const key = Object.keys(item)
        const value = item[key]
        jsonObject[key] = value
      })
      return array
    },
    /**
    * @methods setConfig
    * @description 转换为后端需要的inspectionConfig格式
    * @param {Object} params 数组
    */
    setConfig(array) {
      const arr = []
      let obj = {}
      array.forEach(item => {
        obj[item[0]] = item[1]
        arr.push(obj)
        obj = {}
      })
      const jsonObject = {}
      arr.forEach(item => {
        // console.error("item",item)
        const key = Object.keys(item)
        const value = item[key]
        jsonObject[key] = value
      })
      const jsonString = JSON.stringify(jsonObject)
      return jsonString
    },
    /**
    * @methods changeMachineType
    * @description 判断机器类型转换为i18n对应的字段
    * @param {Object} params 包含机器类型的数据
    */
    changeMachineType(item) {
      if (item.machineType === 1) {
        item.machineType = this.$t('server.machineType.physical')
      } else if (item.machineType === 2) {
        item.machineType = this.$t('server.machineType.virtual')
      } else if (item.machineType === 3) {
        item.machineType = this.$t('server.machineType.docker')
      }
    },
    /**
    * @methods machineTypeToNum
    * @description 将机器类型转换为数字
    * @param {Object} params 机器类型
    */
    machineTypeToNum(type) {
      if (type === this.$t('server.machineType.physical')) {
        type = 1
      } else if (type === this.$t('server.machineType.virtual')) {
        type = 2
      } else if (type === this.$t('server.machineType.docker')) {
        type = 3
      }
      return type
    },
    /**
    * @methods handleSizeChange
    * @description 点击修改每页最大数触发
    * @param {Object} params val 表示一页需要多少条数，pageSize
    */
    handleSizeChange(val) {
      // val 表示一页需要多少条数，pageSize
      this.queryParams.pageSize = `${val}`
      this.getList()
    },
    /**
    * @methods handleCurrentChange
    * @description 点击切换页数触发
    * @param {Object} params val 表示当前第几页,pageIndex
    */
    handleCurrentChange(val) {
      // val 表示当前第几页,pageIndex
      this.queryParams.pageIndex = `${val}`
      this.getList()
    },
    /**
    * @methods handleSelectionChange
    * @description 使用选框时触发
    * @param {Object} params val 表示选中的信息
    */
    handleSelectionChange(val) {
      this.val = val
      this.queryParams.id = ''
      // 封装批量删除id数据
      val.forEach(item => {
        this.queryParams.id += item.id + ','
      })
    },
    /**
    * @methods changeTime
    * @description 修改显示时间为对应时区
    * @param {Object} params time0 表示某一条时间数据
    */
    // changeTimeByZone(time0) {
    //   const moment = require('moment')
    //   // 显示的时间格式
    //   const formatString = 'YYYY-MM-DD HH:mm:ss'
    //   // 获取浏览器的时区
    //   var timezone = (0 - new Date().getTimezoneOffset() / 60)
    //   // 将返回的时间转为时区修改为浏览器时区后显示格式
    //   return moment(time0).utcOffset(timezone).format(formatString)
    // },
    /**
    * @methods handleUpdate
    * @description 编辑处理
    * @param {Object} params row 表示某一行数据
    */
    handleUpdate(row) {
      this.open = true
      this.reset()
      // 深拷贝
      this.form = Object.assign({}, row)
      this.inputData(this.form.config)
      this.form.type = 'edit'
      this.idDisabled = true
      this.title = this.$t('server.title.edit')
    },
    /**
    * @methods edit
    * @description 点击表格上方的编辑按钮触发
    */
    edit() {
      if (this.val.length !== 1) {
        this.$message({
          type: 'error',
          message: this.$t('message.edit.tips')
        })
      } else {
        this.handleUpdate(this.val[0])
      }
    },
    /**
    * @methods inputData
    * @description 封装input框数据
    * @param {Object} params obj1
    */
    inputData(obj1) {
      var obj = [...obj1]
      var arrs = []
      var arr = []
      obj.forEach(item => {
        for (var p in item) {
          arr.push(p)
          arr.push(item[p])
          arrs.push(arr)
          arr = []
        }
      })
      this.config = [...arrs]
    },
    /**
    * @methods handleAdd
    * @description 点击新增按钮触发
    */
    handleAdd() {
      this.idDisabled = false
      this.open = true
      this.reset()
      this.form.type = 'add'
      this.title = this.$t('server.title.register')
    },
    /**
    * @methods addConfig
    * @description 点击表单的+按钮触发，新增配置框
    */
    addConfig() {
      this.$nextTick(() => {
        const arr = ['', '']
        this.config.push(arr)
      })
    },
    /**
    * @methods deleteConfig
    * @description 点击表单的-按钮触发，删除配置框
    * @param {Object} params index 配置框索引
    */
    deleteConfig(index) {
      console.log(index)
      console.log(this.config)
      if (this.config.length <= 1) {
        this.$message({
          type: 'info',
          message: this.$t('message.delete.fail')
        })
      } else {
        this.$nextTick(() => {
          this.config.splice(index, 1)
        })
      }
    },
    /**
    * @methods checkedConfig
    * @description 遍历判断表单内的配置内容是否为空
    * @param {Object} params config 配置内容
    */
    checkedConfig(config) {
      console.log(config)
      const arr = []
      config.forEach(item => {
        item.forEach(i => {
          arr.push(i)
        })
      })
      return !arr.some(v => { return (v === null || v === '') })
    },
    /**
    * @methods submitForm
    * @description 编辑和新增的表单确认按钮
    * @param {Object} params formName 表单名字
    */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断配置文件的值和键是否为空
          if (!this.checkedConfig(this.config)) {
            this.$message({
              type: 'error',
              message: this.$t('server.rules.message.inspectionConfig')
            })
            return false
          }
          if (this.form.type === 'add') {
            // 发送请求新增服务器信息
            this.form.inspectionConfig = this.setConfig(this.config)
            this.config = [['', '']]
            this.form.machineType = this.machineTypeToNum(this.form.machineType)
            this.form.time = this.newTime()
            console.log(this.form)
            this.$api.addServer(this.form).then(res => {
              this.showTipes(res.code, this.$t('message.add.success'), this.$t('message.add.fail'))
            }).catch(() => {
              this.$message({
                type: 'error',
                message: this.$t('message.add.fail')
              })
            })
          } else if (this.form.type === 'edit') {
            this.form.inspectionConfig = this.setConfig(this.config)
            this.config = [['', '']]
            this.form.time = this.newTime()
            this.form.machineType = this.machineTypeToNum(this.form.machineType)
            // 发送请求修改数据
            this.$api.updateServer(this.form).then(res => {
              this.showTipes(res.code, this.$t('message.edit.success'), this.$t('message.edit.fail'))
            })
          }
          // 发送请求编辑或新增
          // 关闭表单
          this.open = false
          // 重置表单
          this.reset()
        } else {
          return false
        }
      })
    },
    /**
    * @methods handleCancel
    * @description 编辑和注册的取消按钮
    */
    handleCancel() {
      // 关闭表单
      this.open = false
      // 重置表单
      this.reset()
    },
    /**
    * @methods configToString
    * @description 将配置内容转换为字符串
    */
    configToString(data) {
      return JSON.stringify(data)
    },
    /**
    * @methods deleteonSubmit
    * @description 删除按钮
    * @param {Object} params row 当前行
    */
    deleteonSubmit(row) {
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        // 发送请求删除当前行数据，重新getList()
        this.$api.deleteServer(row.id).then((res) => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      }).catch(() => {
        this.showTipes('error', this.$t('message.delete.fail'))
      })
    },
    /**
    * @methods handleDelete
    * @description 用户按下删除按钮时触发
    */
    handleDelete() {
      if (this.queryParams.id === undefined || this.queryParams.id === '') {
        this.$message({
          type: 'error',
          message: this.$t('message.delete.tips')
        })
        return
      }
      this.$api.deleteServer(this.queryParams.id).then(res => {
        console.log(res)
        this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
      }).catch(() => {
        this.showTipes('error', this.$t('message.delete.fail'))
      })
    },
    /**
    * @methods handleSearch
    * @description 搜索按钮
    */
    handleSearch() {
      this.queryParams.pageIndex = 1
      this.queryParams.machineType = this.machineTypeToNum(this.queryParams.type)
      this.getList()
    },
    /**
    * @methods handleDetail
    * @description 服务器信息按钮
    * @param {Object} params row 当前行
    */
    handleDetail(row) {
      this.drawer = true
      this.drawerData = row
      this.drawerData.title = this.$t('server.title.serverInfo')
    },
    /**
    * @methods handleExport
    * @description 导出按钮
    */
    handleExport() {
      this.$api.exportServersAsExcelFile().then(res => {
        console.log(res)
        this.exportFile(this.$t('export.serverInfo'), res)
      })
    },
    /**
    * @methods showTipes
    * @description 弹框提示信息
    * @param {Object} params 错误码、提示信息对应的i18n字段
    */
    showTipes(code, sucessMessage, errorMessage) {
      if (code === 1) {
        this.resetQuery()
        this.getList()
        this.$message({
          type: 'success',
          message: sucessMessage
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped></style>
